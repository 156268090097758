import Image from 'next/image'
import Link from 'next/link'
import { Fade } from 'react-awesome-reveal'

import AffiliateCards, {
  InsuranceCard,
  SellYourVehicleCard,
} from '@/components/styleguide/Card/Affiliate'
import { constants } from '@/lib/constants'
import { ROUTES } from '@/lib/routes'

const Grid = ({ isVans, dealCount }) => {
  return (
    <section className="section-grid">
      <div className="grid__row">
        <div className="grid__column">
          <div className="grid__item electric">
            <Fade
              triggerOnce
              className="grid__content">
              <div className="grid__content">
                <h2>Electric leasing</h2>
                <p>
                  Take it to the next level.
                  <br />
                  Join the electric revolution.
                </p>
                <div className="grid__button-container">
                  <Link
                    href={
                      isVans
                        ? '/van-leasing/search/04c10ae500fb77de4d64aebd346dd5d9'
                        : ROUTES.electricVehiclesPage.url
                    }>
                    <a className="button-filled">
                      View {isVans ? 'vans' : 'cars'}{' '}
                      <Image
                        src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                        alt=""
                        height="20"
                        width="20"
                      />
                    </a>
                  </Link>
                </div>
                <div className={`grid__image ${isVans ? 'is-vans' : ''}`}>
                  <Fade triggerOnce>
                    <Image
                      src={`${constants.imageHost.vehicle}/${constants.vehicleImageSizes.DISPLAY}/${
                        isVans ? 'side/peugeot_partner.png' : 'tesla/model-y/tesla_model_y_1.png'
                      }`}
                      height="427"
                      width="640"
                      alt={isVans ? 'Peugeot e-Partner' : 'Tesla Model Y'}
                    />
                  </Fade>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="grid__column">
          <div className="grid__item business">
            <Fade
              triggerOnce
              className="grid__content">
              <div className="grid__content">
                <h2>Business leasing</h2>
                <p>
                  The world moves fast.
                  <br />
                  So can you.
                </p>
                <div className="grid__button-container">
                  <Link href={isVans ? '/van-leasing/search' : ROUTES.businessLeasingPage.url}>
                    <a className="button-filled">
                      View {isVans ? 'vans' : 'cars'}{' '}
                      <Image
                        src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                        alt=""
                        height="20"
                        width="20"
                      />
                    </a>
                  </Link>
                </div>
                <div className="grid__image">
                  <Fade triggerOnce>
                    <Image
                      src={`${constants.imageHost.vehicle}/${constants.vehicleImageSizes.DISPLAY}/${
                        isVans ? 'side/ford_ranger_3.png' : 'side/hyundai_tucson_2024_1.png'
                      }`}
                      height="480"
                      width="720"
                      alt={isVans ? 'Ford Ranger' : 'Hyundai Tucson 2024'}
                    />
                  </Fade>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <AffiliateCards
        className="affiliates"
        width="fixed">
        <SellYourVehicleCard
          position="grid"
          page="homepage"
          isVans={isVans}
        />
        <InsuranceCard
          isVans={isVans}
          page="homepage"
          position="grid"
        />
      </AffiliateCards>
      <div className="grid__container">
        <div className="grid__column">
          <div className="grid__heading">
            <h3>
              Our innovative website breaks down the differences in spec between each trim on a
              certain vehicle - letting you make more informed decisions.
            </h3>
          </div>
        </div>
        <div className="grid__column">
          <div className="grid__stats">
            <div>
              <p>Over</p>
              <p className="gradient-text">{(dealCount / 1000000).toFixed(1)} million</p>
              <p className="gradient-text">lease deals</p>
              <p>Live and ready to go.</p>
            </div>
            <div>
              <p>Provided by</p>
              <p className="gradient-text alternative">80+ approved</p>
              <p className="gradient-text alternative">lease partners</p>
              <p>Giving you incredible deals.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
